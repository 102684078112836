const treeRight = [
  {
    pathLength: '100',
    id: 'r-01',
    d:
      'm -41.57442,168.33339 c 4.777029,0 9.358414,-1.89767 12.736277,-5.27554 3.377878,-3.37787 5.275538,-7.95925 5.275538,-12.73628 0,-4.77703 -1.89766,-9.35841 -5.275538,-12.73628 -1.860168,-1.86018 -4.085338,-3.27146 -6.683647,-4.21047 -1.25629,-0.38809 -2.007878,-0.96443 -2.506886,-1.53571 -1.691167,-2.2121 -1.26933,-6.02467 -0.630321,-8.33531 0.823441,-2.67293 3.714075,-4.38051 6.432812,-3.50924 2.307013,0.73932 3.779071,3.25878 2.986485,5.60995 -0.654415,1.9413 -2.803984,3.17881 -4.787094,2.46373 -1.575902,-0.56825 -2.580555,-2.35013 -1.940976,-3.96424 0.479861,-1.21103 1.898317,-1.98611 3.14138,-1.41822 0.847041,0.38697 1.400222,1.45228 0.895462,2.31852 -0.282184,0.48427 -1.031291,0.83796 -1.495664,0.37271 -0.171358,-0.17168 -0.254752,-0.76309 0.15005,-0.67281',
    fillOpacity: '0.183',
  },
  {
    pathLength: '100',
    id: 'r-02',
    d:
      'm -35.165209,119.99344 c 1.989114,-1.12073 3.045843,-3.52759 2.366171,-5.73383 -0.702405,-2.28004 -3.168153,-3.73663 -5.487269,-2.99343 -1.967911,0.63066 -3.223597,2.77979 -2.54751,4.78537 0.558224,1.65595 2.391835,2.71156 4.083454,2.10159 1.344262,-0.48472 2.201243,-2.00469 1.655673,-3.38155 -0.409326,-1.03302 -1.619287,-1.69418 -2.679636,-1.20975 -0.722536,0.33009 -1.194405,1.23881 -0.763841,1.97772 0.240706,0.41309 0.879705,0.71479 1.275823,0.31793 0.146171,-0.14645 0.217304,-0.65093 -0.127996,-0.57392',
    fillRule: 'evenodd',
  },
  {
    pathLength: '100',
    id: 'path1816',
    d:
      'm -37.995901,111.16938 c -1.022184,0.11628 -2.065515,-0.36867 -2.58198,-1.26915 -0.611008,-1.06533 -0.311877,-2.52184 0.797294,-3.1275 0.881415,-0.48129 2.080364,-0.22382 2.55045,0.70479 0.352694,0.69672 0.136669,1.64133 -0.612286,1.97341 -0.510347,0.22628 -1.208283,0.0511 -1.39636,-0.51979 -0.105144,-0.31912 0.03391,-0.79587 0.427278,-0.81931 0.145156,-0.009 0.443691,0.18901 0.24227,0.33477',
    fillRule: 'evenodd',
  },
  {
    pathLength: '100',
    id: 'path1818',
    d:
      'm -32.609668,115.37167 c -0.08089,-1.02559 0.439828,-2.05154 1.357622,-2.53658 1.085806,-0.57383 2.531108,-0.22454 3.098075,0.9049 0.450546,0.89752 0.151801,2.08685 -0.792504,2.52457 -0.708488,0.3284 -1.645071,0.0799 -1.951067,-0.68012 -0.208509,-0.51786 -0.0093,-1.20932 0.567725,-1.37756 0.322566,-0.094 0.794222,0.0614 0.80406,0.45533 0.0036,0.14537 -0.204229,0.4369 -0.342946,0.23056',
    fillRule: 'evenodd',
  },
  {
    pathLength: '100',
    id: 'path1820',
    d:
      'm -34.767949,111.68921 c -0.747733,-0.96517 -0.898662,-2.32222 -0.303497,-3.40088 0.704116,-1.27612 2.365766,-1.8701 3.663474,-1.11821 1.031238,0.59749 1.508785,1.97222 0.856513,3.02118 -0.489384,0.787 -1.580609,1.14988 -2.378884,0.59481 -0.543954,-0.37824 -0.796235,-1.19412 -0.333108,-1.73659 0.258909,-0.30327 0.828152,-0.45596 1.094294,-0.0714 0.09821,0.1419 0.08165,0.56649 -0.190297,0.452',
    fillRule: 'evenodd',
  },
  {
    pathLength: '100',
    id: 'path1822',
    d:
      'm -29.623348,122.04779 c -0.257637,-1.19343 0.192242,-2.48261 1.194851,-3.19851 1.186147,-0.84695 2.941377,-0.66514 3.78717,0.57341 0.672118,0.98423 0.509196,2.4304 -0.532091,3.09484 -0.781252,0.49851 -1.922191,0.35459 -2.402505,-0.49078 -0.327291,-0.57604 -0.202518,-1.42087 0.449458,-1.71017 0.364483,-0.16173 0.94385,-0.0536 1.01784,0.40814 0.0273,0.1704 -0.170979,0.54621 -0.366824,0.32551',
    fillRule: 'evenodd',
  },
  {
    pathLength: '100',
    id: 'path1824',
    d:
      'm -37.792224,106.77182 c 0.667186,0.37837 1.524518,0.35052 2.141844,-0.11628 0.730333,-0.55226 0.950643,-1.63872 0.366752,-2.37819 -0.463995,-0.58763 -1.361615,-0.76128 -1.955345,-0.26163 -0.445461,0.37487 -0.573234,1.08593 -0.156508,1.5325 0.283963,0.30431 0.813893,0.38801 1.109664,0.0514 0.165349,-0.18818 0.209238,-0.55583 -0.05374,-0.68682 -0.09704,-0.0483 -0.359561,3e-5 -0.263982,0.15886',
    fillRule: 'evenodd',
  },
  {
    pathLength: '100',
    id: 'path1826',
    d:
      'm -25.984648,122.78272 c -0.731524,0.23061 -1.295228,0.87716 -1.374102,1.64708 -0.09331,0.91086 0.551893,1.81232 1.490994,1.8887 0.746267,0.0607 1.48492,-0.47806 1.523677,-1.25308 0.02908,-0.58148 -0.40437,-1.15945 -1.015183,-1.15865 -0.416216,5.4e-4 -0.83852,0.33143 -0.79362,0.77728 0.0251,0.24924 0.264318,0.53184 0.539373,0.42859 0.101498,-0.0381 0.244943,-0.26323 0.06356,-0.30147',
    fillRule: 'evenodd',
  },
  {
    pathLength: '100',
    id: 'path1828',
    d:
      'm -24.908088,119.02246 c -0.290075,-0.71004 -0.152962,-1.5568 0.388852,-2.10946 0.640999,-0.65382 1.746701,-0.73358 2.405551,-0.06 0.523562,0.53524 0.581156,1.44769 0.0098,1.97275 -0.42869,0.39394 -1.150239,0.42986 -1.539944,-0.0405 -0.265549,-0.3205 -0.280886,-0.85678 0.09075,-1.10714 0.207758,-0.13997 0.577994,-0.13655 0.674336,0.141 0.03555,0.10242 -0.04595,0.35662 -0.19127,0.24154',
    fillRule: 'evenodd',
  },
  {
    pathLength: '100',
    id: 'path1830',
    d:
      'm -29.025892,119.28889 c -0.43311,0.29602 -1.007674,0.36226 -1.484718,0.13212 -0.591422,-0.28532 -0.929514,-1.02668 -0.598769,-1.63101 0.225374,-0.41179 0.78111,-0.65117 1.198275,-0.36587 0.233215,0.15949 0.383789,0.54899 0.132978,0.76554 -0.09255,0.0799 -0.392929,0.10033 -0.332813,-0.0999',
    fillRule: 'evenodd',
  },
  {
    pathLength: '100',
    id: 'path1832',
    d:
      'm -27.972574,115.07011 c -0.203513,0.48352 -0.153618,1.05974 0.167194,1.48118 0.39773,0.52249 1.191695,0.70564 1.717753,0.26083 0.358463,-0.30311 0.481974,-0.89547 0.119084,-1.24722 -0.202872,-0.19665 -0.614604,-0.26636 -0.776678,0.0227 -0.05981,0.10665 -0.01979,0.40505 0.164399,0.30614',
    fillRule: 'evenodd',
  },
  {
    pathLength: '100',
    id: 'path1834',
    d:
      'm -24.784562,122.20219 c 0.391473,-0.34922 0.952758,-0.48875 1.455424,-0.32184 0.623187,0.20694 1.053758,0.89871 0.803426,1.54053 -0.170579,0.43734 -0.69094,0.74615 -1.141313,0.51684 -0.251779,-0.12819 -0.451164,-0.49511 -0.230266,-0.7421 0.08152,-0.0911 0.376777,-0.14999 0.342895,0.0563',
    fillRule: 'evenodd',
  },
  {
    pathLength: '100',
    d:
      'm -30.283786,109.55537 c 0.168027,-0.67921 0.724358,-1.22946 1.420783,-1.34539 0.823912,-0.13715 1.681595,0.3986 1.804947,1.2492 0.09802,0.67593 -0.350149,1.37929 -1.05352,1.45898 -0.527722,0.0598 -1.07875,-0.3017 -1.113026,-0.85784 -0.02335,-0.37896 0.253696,-0.78239 0.66217,-0.76706 0.228355,0.009 0.499345,0.21016 0.421103,0.46649 -0.02887,0.0946 -0.225607,0.23808 -0.270818,0.0751',
    id: 'path1836',
    fillRule: 'evenodd',
  },
  {
    pathLength: '100',
    d:
      'm -27.226615,110.3565 c -0.235157,0.26832 -0.326688,0.65088 -0.210158,0.99173 0.14447,0.42258 0.617504,0.71126 1.052497,0.53717 0.296409,-0.11863 0.50331,-0.47437 0.34466,-0.77928 -0.08869,-0.17047 -0.339421,-0.30387 -0.506072,-0.15216 -0.0615,0.056 -0.09975,0.25714 0.04035,0.23286',
    id: 'path1838',
    fillRule: 'evenodd',
  },
  {
    pathLength: '100',
    id: 'path1842',
    d:
      'm -39.993378,106.84507 c 0.356692,-0.205 0.620332,-0.56388 0.677939,-0.97462 0.07865,-0.56075 -0.286531,-1.19271 -0.895651,-1.21726 -0.340525,-0.0137 -0.766814,0.25461 -0.680188,0.64481 0.03197,0.14398 0.307854,0.38014 0.39396,0.14311',
    fillRule: 'evenodd',
  },
  {
    pathLength: '100',
    id: 'path1844',
    d:
      'm -25.698662,115.88803 c -0.267577,-0.3125 -0.395995,-0.73889 -0.312897,-1.14524 0.113447,-0.55476 0.668972,-1.02817 1.251219,-0.84756 0.325501,0.10096 0.637483,0.49643 0.425331,0.83517 -0.07829,0.12501 -0.417274,0.25528 -0.419137,0.003',
    fillRule: 'evenodd',
  },
  {
    pathLength: '100',
    id: 'path1846',
    d:
      'm -32.224837,106.84798 c -0.378233,-0.16185 -0.682063,-0.4874 -0.787346,-0.88858 -0.143735,-0.54769 0.144971,-1.21805 0.747033,-1.31372 0.336579,-0.0535 0.791342,0.16312 0.750979,0.56077 -0.0149,0.14674 -0.261248,0.41356 -0.374503,0.18824',
    fillRule: 'evenodd',
  },
  {
    pathLength: '100',
    id: 'path1848',
    d:
      'm -37.150376,103.95586 c -0.36983,0.18022 -0.814814,0.19709 -1.187126,0.0143 -0.508293,-0.24954 -0.826495,-0.90641 -0.505037,-1.42438 0.179709,-0.28957 0.641012,-0.49187 0.91539,-0.20123 0.101249,0.10726 0.141927,0.46813 -0.102588,0.40641',
    fillRule: 'evenodd',
  },
]

export default treeRight
